import {azure_token_call, azure_token_refresh_call} from "../apiRequests/auth";

const setLoginSessionItems = (response) => {
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('id_token', response.data.id_token);
    localStorage.setItem('isCMUserLoggedIn', 'true');
};


const handleAuthTokenCall = async (code) => {
    if (code) {
        try {
            const response = await azure_token_call(code);
            if (response.status === 200){
                setLoginSessionItems(response);
            } else{
                await handleCognitoAuth();
            }
        } catch (error) {
            console.error('Error during token request:', error);
        }
    }
}


const handleCognitoAuth = async() => {
    document.location.href = "https://maec-login.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=75cedvqm0n6a8qptamln6t7itn&redirect_uri=https://cm.lodamo.local";
    
}

export const getLoginSessionItem = () => {
    const value = localStorage.getItem('isCMUserLoggedIn');
    return value === 'true';
};

export const handleLogout = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('isCMUserLoggedIn');

    // Refresh the whole page
    window.location.reload();
};


export const handleAzureLoginAndToken = async () => {
    const refresh_token = localStorage.getItem('refresh_token')
    if(refresh_token){
        const response = await azure_token_refresh_call(refresh_token);
        if(response.status === 200){
            await getLoginSessionItem();
            return;
        }
    }
    if(await getLoginSessionItem()){
        return;
    }

    // Check if the code parameter is present in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      //first, clear the url param code
      urlParams.delete('code');
      const newUrl = `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
      window.history.replaceState({}, document.title, newUrl);

      // If code is present, handle the token call
      await handleAuthTokenCall(code);
    } else {
      // If code is not present, proceed with Azure login
      await handleCognitoAuth();
    }
    
  };