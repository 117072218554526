import React, { useState, useEffect } from "react";
import './App.css';
import Layout from './components/Layout';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import MainContent from './components/MainContent';
import { getLoginSessionItem, handleAzureLoginAndToken, handleLogout } from "./utilityFunctions/authUtil";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    handleAzureLogin();
    setCurrentNotification({
      message: "System maintenance tonight at 10 PM"
    });
  }, []);

  const handleAzureLogin = async () => {
    await handleAzureLoginAndToken();
    setIsLoggedIn(getLoginSessionItem());
  };

  const handleCMLogout = async () => {
    handleLogout();
  };

  if (isLoggedIn) {
    return (
      <Layout>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="lg:pl-72">
          <Header 
            notification={currentNotification}
            onNotificationClose={() => setCurrentNotification(null)}
            onLogout={handleCMLogout}
            setSidebarOpen={setSidebarOpen}
          />
          <MainContent>
            {/* TODO: Add check-in and register here */}
          </MainContent>
        </div>
      </Layout>
    );
  }
}
