import React from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';

const WarningAlert = ({ message, onClose }) => {
  return (
    <div className="flex items-center px-3 py-1 bg-yellow-100 text-yellow-800 text-sm border-l-4 border-yellow-400">
      <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400 mr-2" aria-hidden="true" />
      <span>{message}</span>
      <button onClick={onClose} className="ml-2 text-yellow-600 hover:text-yellow-800">
        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  );
};

export default WarningAlert;
