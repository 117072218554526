import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  Cog6ToothIcon,
  ClipboardDocumentListIcon,
  IdentificationIcon,
  UserGroupIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/24/outline';

const navigation = [
  {
    name: 'Attendance',
    href: '#',
    icon: ClipboardDocumentListIcon,
    current: false,
    subItems: [
      { name: 'Students', href: '#student-attendance' },
      { name: 'Volunteers', href: '#volunteer-attendance' }
    ]
  },
  {
    name: 'Registration',
    href: '#',
    icon: IdentificationIcon,
    current: false,
    subItems: [
      { name: 'Students', href: '#student-registration' },
      { name: 'Volunteers', href: '#volunteer-registration' }
    ]
  },
  { name: 'Classroom', href: 'classroom', icon: UserGroupIcon, current: false },
  { name: 'Reporting', href: 'report', icon: DocumentDuplicateIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavItem = ({ item }) => {
  return (
    <a
      href={item.href}
      className={classNames(
        item.current
          ? 'bg-gray-50 text-blue-600'
          : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
        'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
      )}
    >
      <item.icon
        className={classNames(
          item.current ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
          'h-6 w-6 shrink-0'
        )}
        aria-hidden="true"
      />
      {item.name}
    </a>
  );
};

const ExpandableNavItem = ({ item, expanded, onToggle }) => {
  return (
    <>
      <button
        onClick={onToggle}
        className={classNames(
          item.current
            ? 'bg-gray-50 text-blue-600'
            : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
          'group flex items-center justify-between w-full p-2 text-sm font-semibold leading-6'
        )}
      >
        <div className="flex items-center gap-x-3">
          <item.icon
            className={classNames(
              item.current ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </div>
        <ChevronRightIcon
          className={classNames(
            expanded ? 'rotate-90' : '',
            'h-5 w-5 text-gray-400 group-hover:text-blue-600'
          )}
          aria-hidden="true"
        />
      </button>
      {expanded && (
        <ul className="mt-1 px-2">
          {item.subItems.map((subItem) => (
            <li key={subItem.name}>
              <a
                href={subItem.href}
                className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-blue-600"
              >
                {subItem.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const Navigation = () => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (itemName) => {
    setExpandedSections(prev => ({
      ...prev,
      [itemName]: !prev[itemName]
    }));
  };

  return (
    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" className="-mx-2 space-y-1">
            {navigation.map((item) => (
              <li key={item.name}>
                {item.subItems ? (
                  <ExpandableNavItem item={item} expanded={expandedSections[item.name]} onToggle={() => toggleSection(item.name)} />
                ) : (
                  <NavItem item={item} />
                )}
              </li>
            ))}
          </ul>
        </li>
        <li className="mt-auto -mx-6">
            <a
                href="#"
                className="flex items-center gap-x-3 px-6 py-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600 w-full"
            >
            <Cog6ToothIcon className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
            Settings
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
