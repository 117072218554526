import axios from 'axios'
import qs from 'qs';

export const azure_token_call = async (code) => {
    //get id and secret from secrets manager. We can get some creds for the app itself
    const data = {
      "grant_type": "authorization_code",
      "client_id": "75cedvqm0n6a8qptamln6t7itn",
      "code": code,
      "redirect_uri": "https://cm.lodamo.local"
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: 'https://maec-login.auth.us-west-2.amazoncognito.com/oauth2/token',
    };
    
    const response = await axios(options)
    return response
  }

  export const azure_token_refresh_call = async (refresh_token) => {
    try{
      //get id and secret from secrets manager. We can get some creds for the app itself
      const data = {
        "grant_type": "refresh_token",
        "client_id": "75cedvqm0n6a8qptamln6t7itn",
        "refresh_token": refresh_token
      }
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url: 'https://maec-login.auth.us-west-2.amazoncognito.com/oauth2/token',
      };
      
      const response = await axios(options)
      return response
    } catch (error){
      return { status: 400, data: { error: 'token expired' } };
    }
    
  }